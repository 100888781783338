import React from "react";

export default function TWInputText({ id, name, type, autoComplete = "", value, onChange, required = false, error = false, ...rest }) {
  return (
    <input
      id={id}
      name={name}
      type={type}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      required={required}
      className={` block w-full rounded-md ${
        error ? "border-2 border-red-500" : "border-0"
      } py-1.5 p-2.5 text-section-title-color ring-1 ring-grey placeholder:text-grey  sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-main`}
      {...rest}
    />
  );
}
