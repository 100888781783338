import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../context/Data";
import DTC_V2 from "../views/DTC_V2/DTC_V2";
import Depletion_V2 from "../views/Depletion_V2/Depletion_V2";
import NoRouteV2 from "../components/StatusPages/NoRouteV2";
import { AuthContext } from "../context/Authorization";
import ErrorV2 from "../components/StatusPages/Error";

export default function PortalRoutesV2() {
  const { account } = React.useContext(DataContext);
  const { AllowedTo, authData } = React.useContext(AuthContext);

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    let default_route = authData?.user?.protected?.default_route;
    if (location.pathname === "/" && default_route) {
      navigate(default_route, { replace: true });
    }
  }, [authData?.user?.protected?.default_route, location.pathname, navigate]);

  // Static Compiled Routes
  let routes = [
    { path: "v2/dtc/*", component: DTC_V2, permission: "ROUTE_DTC_V2_VIEW" },
    { path: "v2/depl/*", component: Depletion_V2, permission: "ROUTE_DEPL_V2_VIEW" },
    { path: "v2/dtc/error", component: ErrorV2 },
    { path: "v2/depl/error", component: ErrorV2 },
    { path: "v2/dtc/noaccess", component: NoRouteV2 },
    { path: "v2/depl/noaccess", component: NoRouteV2 },
  ];

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((item, index) => {
          let RouteComponent = item.component;

          const routeProps = (item.props && JSON.parse(item.props)) || {};

          return (
            <Route
              path={item.path}
              key={index}
              element={
                <AllowedTo perform={item.permission} no={() => <NoRouteV2 />} data={account}>
                  <RouteComponent {...routeProps}></RouteComponent>
                </AllowedTo>
              }
            />
          );
        })}
      </Routes>
    </React.Suspense>
  );
}
