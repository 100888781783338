function getIframeLinkByNavlink(routes, navlink) {
  for (const section of routes) {
    const link = section.links.find((link) => link.navlink === navlink);
    if (link) {
      return link;
    }
  }
  return null;
}
export default getIframeLinkByNavlink;
