import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/performance";
import "firebase/compat/analytics";

import { FirebaseConfig } from "./Config";
import { LoggingContext } from "./Logger";
const FirebaseContext = React.createContext();

firebase.initializeApp(FirebaseConfig);
let auth = firebase.auth();
let db = firebase.firestore();
let functions = firebase.functions();

if (process.env.REACT_APP_LOCAL_ENV === "true") {
  functions.useEmulator("127.0.0.1", 5001);
}

let storage = firebase.storage();
let perf = firebase.performance();

// db.enablePersistence({ synchronizeTabs: true }).catch((err) => {
//   if (err.code === "failed-precondition") {
//     console.log("Multiple Tabs Open persistence not enabled");
//   } else if (err.code === "unimplemented") {
//     console.log("The current browser does not supoprt all of the features requried to enable persistance");
//   }
//   console.log(err);
// });

// Custom Abstraction for sign-in, up, out & pwd reset
auth.doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);
auth.doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);
auth.doSignOut = () => auth.signOut();
auth.doPasswordReset = (email) => auth.sendPasswordResetEmail(email);
auth.doPasswordUpdate = (password) => auth.currentUser.updatePassword(password);

// Create new Function on functions object
functions.createNewUser = (userObject) => {
  return new Promise((resolve, reject) => {
    const newUser = functions.httpsCallable("createNewUser");
    newUser(userObject).then((result) => resolve(result.data));
  });
};
functions.toggleUserDisabledStatus = (userObject) => {
  return new Promise((resolve, reject) => {
    const resultStatus = functions.httpsCallable("toggleUserDisabledStatus");
    resultStatus(userObject).then((result) => resolve(result.data));
  });
};

functions.createNewAccount = (accountObject) => {
  return new Promise((resolve, reject) => {
    const createNewAccount = functions.httpsCallable("createNewAccount");
    createNewAccount(accountObject).then((result) => resolve(result.data));
  });
};

functions.resetUserPassword = (userId, newPassword) => {
  return new Promise((resolve, reject) => {
    const resetUserPassword = functions.httpsCallable("resetUserPassword");
    resetUserPassword({ uid: userId, pwd: newPassword }).then((result) => resolve(result.data));
  });
};
// Onboarding Functions
functions.onboarding = (path, obj) => {
  return new Promise((resolve, reject) => {
    const onboarding = functions.httpsCallable(`onboarding/${path}`);
    onboarding(obj).then((result) => resolve(result));
  });
};
functions.onboardingAccountCreate = (accountObject) => {
  return new Promise((resolve, reject) => {
    const onboardingAccountCreate = functions.httpsCallable("onboarding/account/create");
    onboardingAccountCreate(accountObject).then((result) => resolve(result));
  });
};
functions.onboardingAccountUpdate = (accountObject) => {
  return new Promise((resolve, reject) => {
    const onboardingAccountUpdate = functions.httpsCallable("onboarding/account/update");
    onboardingAccountUpdate(accountObject).then((result) => resolve(result));
  });
};
functions.onboardingGroupCreate = (accountObject) => {
  return new Promise((resolve, reject) => {
    const onboardingGroupCreate = functions.httpsCallable("onboarding/group/create");
    onboardingGroupCreate(accountObject).then((result) => resolve(result));
  });
};
functions.onboardingGroupUpdate = (accountObject) => {
  return new Promise((resolve, reject) => {
    const onboardingGroupUpdate = functions.httpsCallable("onboarding/group/update");
    onboardingGroupUpdate(accountObject).then((result) => resolve(result));
  });
};

functions.pullData = (accountObject) => {
  return new Promise((resolve, reject) => {
    const pullData = functions.httpsCallable("onboarding/account/pull");
    pullData(accountObject).then((result) => resolve(result));
  });
};

functions.testConnection = (obj) => {
  return new Promise((resolve, reject) => {
    const testConnection = functions.httpsCallable("onboarding/account/connection");
    testConnection(obj).then((result) => resolve(result));
  });
};

functions.moveOnboarding = (type, account_eid) => {
  return new Promise((resolve, reject) => {
    const moveOnboarding = functions.httpsCallable(`onboarding/account/${type}/${account_eid}`);
    moveOnboarding().then((result) => resolve(result));
  });
};

functions.getVIPIDList = () => {
  return new Promise((resolve, reject) => {
    const getVIPIDList = functions.httpsCallable("onboarding/storage/folders/list");
    getVIPIDList().then((result) => resolve(result));
  });
};

functions.dags = (path, obj) => {
  return new Promise((resolve, reject) => {
    const cloudComposerFn = functions.httpsCallable(`cloudComposer/${path}`);
    cloudComposerFn(obj).then((result) => {
      resolve(result);
    });
  });
};

functions.sendEmailVerification = (obj) => {
  return new Promise((resolve, reject) => {
    const fn = functions.httpsCallable("sendEmailVerification");
    fn(obj).then((result) => resolve(result.data));
  });
};

functions.refreshUserSession = (obj) => {
  return new Promise((resolve, reject) => {
    const fn = functions.httpsCallable("refreshUserSession");
    fn(obj).then((result) => resolve(result.data));
  });
};

functions.configAstrato = (obj) => {
  return new Promise((resolve, reject) => {
    const fn = functions.httpsCallable("configAstrato");
    fn(obj)
      .then((result) => resolve(result.data))
      .catch((error) => reject(error));
  });
};

function FirebaseProvider({ children }) {
  return (
    <FirebaseContext.Provider value={{ firebase, auth, db, functions, storage, perf }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export { FirebaseProvider, FirebaseContext };
