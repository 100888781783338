import React from "react";

export default function TWAlert({ showAlert, fcClose }) {
  const { type, message } = showAlert;

  const isError = type === "error";

  return (
    ["error", "success"].includes(type) && (
      <div className={`relative p-4 mb-4 text-sm ${isError ? "text-main" : "text-green-dark"} rounded-lg  ${isError ? "bg-sub " : "bg-green"} `} role="alert">
        <button onClick={fcClose} className={`absolute top-0 right-0 p-1 m-1 ${isError ? "text-red-500 hover:text-red-600" : "text-green-500 hover:text-green-700"}`} aria-label="Close">
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <span className="font-bold">{isError ? "Error:" : ""}</span> {message}
      </div>
    )
  );
}
