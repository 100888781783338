import React from "react";
import { LoggingContext } from "./Logger";
import { FirebaseContext } from "./Firebase";
import { AuthProvider } from "./Authorization";
import { DataProvider } from "./Data";
import StatusPage from "../components/StatusPages/EnoLoading";

const UserContext = React.createContext();

function UserProvider({ children }) {
  const { log } = React.useContext(LoggingContext);
  log.debug("User Rendered ...");
  const { auth, db, functions, storage } = React.useContext(FirebaseContext);

  const [user, setUser] = React.useState(auth.currentUser);
  const [loading, setLoading] = React.useState(true);

  if (log.getLevel() < 2) {
    window.enolog.Firebase = {
      auth: auth,
      db: db,
      storage: storage,
      functions: functions,
    };
    window.enolog.User = {
      user: user,
      loading: loading,
    };
  }

  const getFreshWidgetSSO = () => {
    log.debug(`@@@@@@@@@@@@@@@@ CALL GET FRESWIDGETSSO ${new Date()} @@@@@@@@@@@@@@`);
    const token = functions.httpsCallable("getFreshWidgetSSOToken");
    token().then((result) => {
      window.FreshworksWidget("authenticate", {
        token: result.data.freshworks_widget_auth_token,
        callback: () => {
          const refreshToken = functions.httpsCallable("getFreshWidgetSSOToken");
          refreshToken().then((result) => {
            window.FreshworksWidget("authenticate", {
              token: result.data.freshworks_widget_auth_token,
            });
          });
        },
      });
    });
  };

  //Modify user state as state changes
  React.useEffect(() => {
    let unregisterAuthObserver = auth.onAuthStateChanged((u) => {
      log.debug(`@@@@@@@@@@@@@@@@ FIREBASE USER TOKEN WAS REFRESHED ${new Date()} @@@@@@@@@@@@@@`);
      let refUser = null;
      if (u) {
        log.debug(`@@@@@@@@@@@@@@@@ USER  ${u} @@@@@@@@@@@@@@`);
        // Only when User is null or changing user uids.
        if (!user || user.uid != u.uid) {
          log.debug("Getting User Info and setting in session state");

          refUser = {};
          refUser.auth = u;
          refUser.uid = u.uid;
          // refUser.getQlikToken = getQlikToken;
          getFreshWidgetSSO(); //Authenticate Fresh Widget

          //Call DB to get the user profile
          db.collection("users")
            .doc(u.uid)
            .get()
            .then((doc) => {
              if (!doc.exists) {
                refUser.profile = null;
                setUser(refUser);
                setLoading(false);
              } else {
                log.debug("Profile", JSON.stringify(doc.data()));
                refUser.profile = doc.data();
                setUser(refUser);
                setLoading(false);
                //Production only hotjar user attributes
                if (window.location.host === "my.enolytics.com") {
                  window.hj("identify", refUser?.profile?.id, {
                    email: refUser?.profile?.email,
                    account: refUser?.profile?.protected?.account,
                    uid: refUser?.uid,
                    name: refUser?.profile?.name,
                    state: refUser?.profile?.protected?.home_state?.join(","),
                    timezone: refUser?.profile?.protected?.time_zone,
                  });
                }
              }
            })
            .catch((error) => console.error(error));
        }
      } else {
        // When no user object logged in ... use the refUser Null
        setUser(refUser);
        setLoading(false);
      }
    });

    //For Unmount and destroy
    return () => {
      log.debug("Destroying User Auth Component");
      unregisterAuthObserver();
    };
  }, [auth]);

  const doSignout = auth.doSignout;

  //wait until component completely renders to show the children
  if (loading) {
    log.debug("User context still loading...");
    return <StatusPage />; //<div>User Context Loading ...</div>; //TODO: Add Logging-you-in animation or something other than blank screen for the login process.
  }

  if (user)
    return (
      <UserContext.Provider value={{ user }}>
        <AuthProvider>
          <DataProvider>{children}</DataProvider>
        </AuthProvider>
      </UserContext.Provider>
    );
  else {
    return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
  }
}

export { UserProvider, UserContext };

/**
 * User Context:
 *  1. Maintain logged in user. (user)
 *  2. Get SSO Tokens:
 *    a. getQlikToken
 *    b. getFreshWidgetSSO
 *  3. Manage Impersonation of User for Admins
 */

//TODO:
/**
 * X 1. Remove version
 * X 2. Remove removePassword
 * 3. Refactor Impersonation and account switching
 * 4. Write user update code
 * 5. Write user password change code
 * X 6. Add method to context auth.doSignout
 */
