/**
 * The Authorization Context is meant to control access to UI components and pages from a licensing and features standpoint. NOT to secure the customer data.
 * Customer data is secured via Controls (security rules) on Firestore and Qlik themselves. Do not assume this context will protect sensitive data.
 * Sensitive data should never be brought back to the UI Authorization determination if the logged in user does not have permissions to see it.
 *
 * https://www.npmjs.com/package/react-abac#allowedto
 */

import React from "react";
import { AbacProvider, AllowedTo as ABACAllowedTo, NotAllowedTo as ABACNotAllowedTo, useAbac } from "react-abac";
import StatusPage from "../components/StatusPages/EnoLoading";
import { UserContext } from "./User";
import { FirebaseContext } from "./Firebase";
import { LoggingContext } from "./Logger";
import { QlikConfig } from "./Config";

const AuthContext = React.createContext();

//~ AUTH PROVIDER
function AuthProvider({ children }) {
  const { log } = React.useContext(LoggingContext);
  log.debug("Auth Provider Rendered...", window.location.pathname);

  // Get Additional Context
  const { user } = React.useContext(UserContext);
  const { functions } = React.useContext(FirebaseContext);

  // Auth State
  const [authData, setAuthData] = React.useState(null);

  // ~DEBUG
  React.useEffect(() => {
    if (log.getLevel() < 2) {
      window.enolog.Auth = {
        authData: authData,
      };
    }
  }, [authData]);
  // ~DEBUG

  // Call Firebase Function authData
  const getAuthData = () => {
    log.debug("getAuthData executed...");
    return new Promise((resolve, reject) => {
      const results = functions.httpsCallable("authData")();
      resolve(results);
    });
  };

  //^ Account Rules Creation.
  function buildAccountRules(accountRoles, accounts) {
    /**
     * buildAccountRules: Builds a user role "AUTO_ACCOUNT_ROLE" that uses dynamic ABAC function execution to determine if account has priviledge
     */
    let accountPerms = {};
    for (let index = 0; index < Object.keys(accountRoles).length; index++) {
      const perms = Object.values(accountRoles)[index];
      for (let p = 0; p < Object.keys(perms).length; p++) {
        const perm = Object.keys(perms)[p];
        accountPerms[perm] = (acct, user) => {
          return accountHasPermission(perm, accounts[acct], accountRoles);
        };
      }
    }
    return { AUTO_ACCOUNT_ROLE: accountPerms };
  }

  //^ Function to check if account has a role with permission requested.
  function accountHasPermission(permission, account, accountRules) {
    /** accountRules can be passed as parameter or it will try to use authData cached accountRules */
    accountRules = accountRules ? accountRules : authData.accountRules;
    try {
      // Iterate through roles attached to account
      for (let index = 0; index < account.roles.length; index++) {
        const role = account.roles[index];
        const isAllowed = accountRules[role][permission] || false;
        if (isAllowed) return true;
      }
    } catch (error) {
      return false; // Default to deny permission
    }
    return false; // Default to deny permission
  }

  //& Wrap ABAC AllowedTo and NotAllowedTo and Hook Functions
  const AllowedTo = (props) => {
    // if (authData?.user?.protected?.roles?.includes("GLOBAL_ADMIN")) {
    //   return props.children;
    // }
    return (
      <ABACAllowedTo perform={props.perform} data={props.data} yes={props.yes} no={props.no}>
        {props.children}
      </ABACAllowedTo>
    );
  };
  const NotAllowedTo = (props) => {
    return (
      <ABACNotAllowedTo perform={props.perform} data={props.data} yes={props.yes} no={props.no}>
        {props.children}
      </ABACNotAllowedTo>
    );
  };
  const useAuth = () => {
    const { userHasPermissions: ABAC_userHasPermission } = useAbac();

    const userHasPermission = (permissions, data) => {
      const result = ABAC_userHasPermission(permissions, data);
      // if (authData?.user?.protected?.roles?.includes("GLOBAL_ADMIN")) {
      //   return true;
      // }
      return result;
    };

    return { userHasPermission, accountHasPermission };
  };

  // Logout Qlik
  const logoutQlik = () => {
    let qpsUrl = `${(QlikConfig.isSecure ? "https://" : "http://") + QlikConfig.host + (QlikConfig.port ? `:${QlikConfig.port}` : "") + "/" + QlikConfig.prefix + "/qps"}`;
    const logoutConfig = {
      method: "DELETE",
      credentials: "include",
      url: `${qpsUrl}/user?xrfkey=somerandomstring`,
      headers: {
        "X-Qlik-XrfKey": "somerandomstring",
        "Content-Type": "application/json",
      },
    };

    // Call Qlik to Logout
    fetch(logoutConfig.url, logoutConfig)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        log.error(`Failed attempting to Logout of Qlik Server`);
        log.error(error);
      });
  };

  // Initialize authData State when user changes

  React.useEffect(() => {
    getAuthData().then((results) => {
      //~ If Demo environment limit to Demo Accounts.
      if (window.location.host === "demo.enolytics.com") {
        let demo_accounts = {
          300000: results.data.accounts["300000"],
          300001: results.data.accounts["300001"],
          300002: results.data.accounts["300002"],
          300003: results.data.accounts["300003"],
        };
        results.data.accounts = demo_accounts;
        results.data.user.protected.default_account = "300001";
      }

      let userRules = results.data.userRules || {};
      let accountRules = buildAccountRules(results.data.accountRules || {}, results.data.accounts); //^ Rules w/ Functions to evaluate permissions for accounts
      let finalRules = { ...accountRules, ...userRules };

      results.data.rules = finalRules;
      setAuthData(results.data);
    });
  }, [user]);

  // ********* RETURN **************************************************************************************
  if (authData) {
    return (
      <AuthContext.Provider value={{ AllowedTo, NotAllowedTo, useAuth, authData, logoutQlik }}>
        <AbacProvider
          user={authData?.user}
          roles={authData?.user?.protected.roles} // Roles of logged in user
          permissions={authData?.user?.protected?.permissions} // Direct Permissions of logged in user
          rules={authData?.rules}
        >
          {children}
        </AbacProvider>
      </AuthContext.Provider>
    );
  } else {
    return <StatusPage />;
  }
}

export { AuthProvider, AuthContext };

/**
 * Examples of use in react components.
 */
// const { userHasPermission } = useAuth();

// if (userHasPermission(["APP_PERMISSION"],"100002")) {
//   return <div>User Has Permissions</div>;
// }

// return <AllowedTo perform={"APP_PERMISSION"} data="100002" no={() => <div>_NOPE</div>} yes={() => <div>_YUP</div>}></AllowedTo>;
