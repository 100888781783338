import React, { useContext, useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import MenuItem from "./MenuItem";
import { DataContext } from "../../context/Data";
import { AuthContext } from "../../context/Authorization";

export default function SideNavV2({ route }) {
  const { accountLogo, v2Routes, account } = useContext(DataContext);

  const { AllowedTo } = React.useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(true);
  const [pinnedItems, setPinnedItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (Object.keys(v2Routes).length > 0) {
      setMenuItems(v2Routes[`${route}_routes`]);
    }
  }, [v2Routes]);

  useEffect(() => {
    const storedPinnedItems = JSON.parse(localStorage.getItem("eno:pinnedItems")) || [];
    setPinnedItems(storedPinnedItems);
  }, []);

  const togglePin = (option) => {
    const alreadyPinned = pinnedItems.find((i) => i.label === option.label);

    let data = [option, ...pinnedItems];
    if (alreadyPinned) {
      data = pinnedItems.filter((i) => i.label !== option.label);
    }

    setPinnedItems(data);
    localStorage.setItem("eno:pinnedItems", JSON.stringify(data));
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button onClick={toggleSidebar} className="fixed top-4 left-4 z-50 p-2  text-font-color ">
        <FaBars className="h-5 w-5" />
      </button>
      <div className={` !text-font-color transition-all duration-300 flex flex-col ${isOpen ? "min-w-[320px] w-[320px]" : "min-w-0 w-0 "} overflow-hidden h-full`}>
        <div className="w-full my-2">
          <img src={accountLogo} alt="Logo" className="w-full h-full" />
        </div>
        <div className=" bg-main flex-grow overflow-y-auto scrollbar-thin scrollbar-webkit">
          {[{ title: "Favorites", links: pinnedItems, permission: "DTC_NAV_KPI_DASHBOARD", position: 0 }, ...menuItems].map((item, index) => {
            return (
              <AllowedTo key={index} perform={item.permission} data={account}>
                <MenuItem key={index} item={item} subitems={item.subitems} togglePin={togglePin} pinnedItems={pinnedItems} />
              </AllowedTo>
            );
          })}
        </div>
      </div>
    </>
  );
}
