const randomImage = () => {
  const images = {
    "001": require("../assets/img/001.jpg"),
    "002": require("../assets/img/002.jpg"),
    "003": require("../assets/img/003.jpg"),
    "004": require("../assets/img/004.jpg"),
    "005": require("../assets/img/005.jpg"),
    "006": require("../assets/img/006.jpg"),
    "007": require("../assets/img/007.jpg"),
  };

  const keys = Object.keys(images);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return images[randomKey];
};

export default randomImage;
