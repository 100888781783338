import React from "react";

const ErrorV2 = () => {
  return (
    <div className="flex mt-24 justify-center h-screen">
      <div className="text-center text-main">
        <h1 className="text-2xl font-bold mb-4">Error</h1>
        <p className="text-subsection-title-color">Sorry, something was wrong.</p>
        <p className="mt-4">Please, try again or contact support for assistance.</p>
        <button
          onClick={() => {
            window.open("https://support.enolytics.com/support/login", "_blank");
          }}
          className="mt-8 inline-block bg-main text-white text-xl font-semibold py-3 px-6 rounded-lg hover:bg-main-hover transition-colors duration-300"
        >
          Contact Support
        </button>
      </div>
    </div>
  );
};

export default ErrorV2;
