import React from "react";

export default function TWButton({ onClick, isLoading, disabled, text }) {
  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={disabled}
      className={`${
        isLoading && "disabled:opacity-85"
      } items-center flex w-full justify-center rounded-md bg-main  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-main-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600`}
    >
      {isLoading ? (
        <div data-testid="loading">
          <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
        </div>
      ) : (
        <>{text}</>
      )}
    </button>
  );
}
