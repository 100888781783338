import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Authorization";
import { DataContext } from "../../context/Data";

import { EuiHeaderSectionItemButton, EuiPopoverTitle, EuiButton, EuiSelectable, EuiPopover, EuiAvatar, EuiBadge, EuiPopoverFooter, EuiHighlight, EuiText, useGeneratedHtmlId } from "@elastic/eui";

const Spaces = () => {
  //^ Context
  const { useAuth, authData } = React.useContext(AuthContext);
  const { account, changeAccountSpace, space } = React.useContext(DataContext);
  const { userHasPermission, accountHasPermission } = useAuth();
  const navigate = useNavigate();

  const headerSpacesPopoverId = useGeneratedHtmlId({
    prefix: "headerSpacesPopover",
  });

  // Component State
  const [spaces, setSpaces] = React.useState([]);
  const [additionalSpaces, setAdditionalSpaces] = React.useState([]);
  const [selectedSpace, setSelectedSpace] = React.useState(spaces.filter((option) => option.checked)[0]);
  const [isOpen, setIsOpen] = React.useState(false);

  const colorScheme = ["#ffe6cc", "#ff99a6", "#ffbf66", "#ff7380", "#ffb333", "#ff4d59", "#ff9900", "#ff1a33", "#d98200", "#b31224", "#8c5400", "#660a14"];

  // Create Spaces Array for Menu
  React.useEffect(() => {
    let authSpaces = Object.entries(authData?.accounts).reduce((acc, [k, v]) => {
      let avatarColor = colorScheme[k.slice(-1)];

      if (userHasPermission(["ROUTE_DTC_USER"], k) && accountHasPermission("ROUTE_DTC_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DTC",
          version: "v1",
          prepend: <EuiAvatar type="space" name={v?.account_name || ""} size="s" color={avatarColor} />,
          append: <EuiBadge color="#00599d">DTC</EuiBadge>,
          searchableLabel: `${v?.account_name} ${k}`,
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "dtc",
          },
          checked: k === account && space === "dtc" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_DEPL_USER"], k) && accountHasPermission("ROUTE_DEPL_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DEPL",
          version: "v1",
          prepend: <EuiAvatar type="space" name={v?.account_name || ""} size="s" color={avatarColor} />,
          append: <EuiBadge color="#EFB700">DEPLETION</EuiBadge>,
          searchableLabel: `${v?.account_name} ${k}`,
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "depl",
          },
          checked: k === account && space === "depl" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_DTC_V2_USER"], k) && accountHasPermission("ROUTE_DTC_V2_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DTC",
          version: "v2",
          prepend: <EuiAvatar type="space" name={v?.account_name || ""} size="s" color={avatarColor} />,
          append: <EuiBadge color="#EFB700">DTC V2</EuiBadge>,
          color: "#EFB700",
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "v2/dtc",
          },
          checked: k === account && space === "v2/dtc" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_DEPL_V2_USER"], k) && accountHasPermission("ROUTE_DEPL_V2_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DEPL",
          version: "v2",
          prepend: <EuiAvatar type="space" name={v?.account_name || ""} size="s" color={avatarColor} />,
          append: <EuiBadge color="#EFB700">DEPLETION V2</EuiBadge>,
          color: "#EFB700",
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "v2/depl",
          },
          checked: k === account && space === "v2/depl" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_HAAS_VIEW"], k) && accountHasPermission("ROUTE_HAAS_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "HAAS",
          version: "v1",
          prepend: <EuiAvatar type="space" name={v?.account_name || ""} size="s" color={avatarColor} />,
          append: <EuiBadge color="#EFB700">HAASBROS</EuiBadge>,
          searchableLabel: `${v?.account_name} ${k}`,
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "haasbros",
          },
          checked: k === account && space === "haasbros" ? "on" : undefined,
        });
      }
      return acc;
    }, []);

    authSpaces.sort((x, y) => {
      return x.data.account == account ? -1 : y.data.account == account ? 1 : 0;
    });

    //Update Session State
    setSelectedSpace(authSpaces.filter((option) => option.checked)[0]);
    // setSpaces(authSpaces.slice(0, 2));
    // setAdditionalSpaces(authSpaces.slice(2));
    setSpaces(authSpaces);
    setAdditionalSpaces([]);
  }, [authData]);

  const isListExtended = () => {
    return spaces.length > 4 ? true : false;
  };

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  const onChange = (options) => {
    let checked = options.filter((option) => option.checked)[0];
    const {
      data: { account, route },
    } = checked;

    setSpaces(options);
    setSelectedSpace(checked);
    setIsOpen(false);
    changeAccountSpace(account, route);
    navigate(route);
  };

  const addMoreSpaces = () => {
    setSpaces(spaces.concat(additionalSpaces));
  };

  const button = (
    <EuiHeaderSectionItemButton aria-controls={headerSpacesPopoverId} aria-expanded={isOpen} aria-haspopup="true" aria-label="Spaces menu" onClick={onMenuButtonClick}>
      {selectedSpace?.prepend || <EuiAvatar type="space" name="S P" size="s" />}
    </EuiHeaderSectionItemButton>
  );

  const renderAccountOption = (option, searchValue) => {
    return (
      <>
        <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
        <EuiText size="xs" color="subdued" className="eui-displayBlock">
          <i>
            <small>
              <EuiHighlight search={searchValue}>{option.secondaryContent || ""}</EuiHighlight>
            </small>
          </i>
        </EuiText>
      </>
    );
  };

  return (
    <EuiPopover id={headerSpacesPopoverId} button={button} isOpen={isOpen} anchorPosition="downLeft" closePopover={closePopover} panelPaddingSize="none" repositionOnScroll>
      <EuiSelectable
        {...{
          searchable: isListExtended(),
          searchProps: {
            placeholder: "Find a space",
            compressed: true,
          },
        }}
        options={spaces}
        singleSelection="always"
        style={{ width: 335 }}
        onChange={onChange}
        listProps={{
          rowHeight: 45,
          showIcons: false,
        }}
        renderOption={isListExtended() && userHasPermission(["GLOBAL_ADMIN"]) ? renderAccountOption : undefined}
      >
        {(list, search) => (
          <>
            <EuiPopoverTitle paddingSize="s">{search || "Your Enolytics Spaces"}</EuiPopoverTitle>
            {list}

            {additionalSpaces.length > 0 ? (
              <EuiPopoverFooter paddingSize="s">
                <EuiButton size="s" fullWidth onClick={addMoreSpaces} disabled={isListExtended()}>
                  View more spaces
                </EuiButton>
              </EuiPopoverFooter>
            ) : null}
          </>
        )}
      </EuiSelectable>
    </EuiPopover>
  );
};

export default Spaces;
