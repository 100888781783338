import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../context/Firebase";

import { LoggingContext } from "../../context/Logger";

import EnoLogo from "../../assets/Enolytics_logo-1.jpg";
import TWInputText from "../TWInputText";
import TWButton from "../TWButton";
import TWAlert from "../TWAlert";
import randomImage from "../../utils/randomImage";

const SignInPage = (props) => {
  const navigate = useNavigate();
  const { log } = React.useContext(LoggingContext);
  const { auth, functions } = React.useContext(FirebaseContext);
  const [formState, setFormState] = React.useState({ email: "", password: "" });
  const [showAlert, setShowAlert] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingPwd, setIsloadingPwd] = useState(false);
  const [image, setImage] = useState(randomImage());

  useEffect(() => {
    setShowAlert({});
  }, [showPassword]);

  useEffect(() => {
    setImage(randomImage());
  }, []);

  const { redirect = true } = props;
  log.debug("redirect:", redirect);

  const onChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  // const handleSignInWithGoogle = () => {
  //   auth
  //     .signInWithPopup(provider)
  //     .then((result) => {})
  //     .catch((error) => {
  //       setShowAlert({ type: "error", message: "Something is wrong. Please try again or contact support." });
  //     });
  // };

  const handleSignInPassword = (event) => {
    if (validateForm()) {
      setIsloading(true);
      const { email, password } = formState;
      event.preventDefault();
      setIsloadingPwd(true);
      auth
        .doSignInWithEmailAndPassword(email, password)
        .then((resp) => {
          if (redirect) {
            setIsloadingPwd(false);
            navigate("/");
          }
        })
        .catch((error) => {
          setIsloadingPwd(false);
          setShowAlert({ type: "error", message: "Something is wrong. Please try again or contact support." });
        });
    }
  };

  const validateForm = () => {
    let valErrors = {};
    if (formState.email === "") {
      valErrors = { type: "error", message: "Email is required." };
    }

    if (formState.email !== "") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const result = emailPattern.test(formState?.email);
      if (!result) valErrors = { type: "error", message: "Invalid email." };
    }

    setShowAlert(valErrors);
    if (Object.keys(valErrors).length > 0) {
      return false;
    }

    return true;
  };
  const handleSendLinkAuth = async () => {
    if (validateForm()) {
      setIsloading(true);
      const result = await functions.sendEmailVerification({ email: formState.email, hostName: window.location.origin });
      if (result.success) {
        setFormState({ email: "", password: "" });
        window.localStorage.setItem("@eno:email", formState.email);
        setIsloading(false);
        setShowAlert({ type: "success", message: "We are sending you an email with a link to login." });
      } else {
        setIsloading(false);
        setShowAlert({ type: "error", message: "Something is wrong. Please try again or contact support." });
      }
    }
    setIsloading(false);
  };

  return (
    <div className="h-screen flex flex-col sm:flex-row">
      {/* Formulário de Sign In */}
      <div className="w-full lg:w-1/3 flex items-center justify-center bg-white p-8">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img alt="Your Company" src={EnoLogo} className="mx-auto h-10 w-auto" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-section-title-color">Sign in to your account</h2>
          </div>

          <div className="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-3">
              <TWInputText placeholder="Email" id="email" name="email" type="email" required autoComplete="email" value={formState?.email} onChange={onChange} />

              {showPassword ? (
                <>
                  <TWInputText placeholder="Password" id="password" name="password" type="password" required autoComplete="current-password" value={formState?.password} onChange={onChange} />
                  <div className="flex items-center justify-end">
                    <TWButton text="Sign in with password" onClick={handleSignInPassword} isLoading={isLoadingPwd} />
                  </div>
                </>
              ) : (
                <TWButton text="Sign in with email" onClick={handleSendLinkAuth} isLoading={isLoading} />
              )}
              <TWAlert showAlert={showAlert} fcClose={() => setShowAlert({})} />
              <div className="flex justify-between">
                <div>
                  <button onClick={() => setShowPassword(!showPassword)} className="font-semibold text-main hover:text-main-hover">
                    Continue with {showPassword ? "Email" : "Password"}
                  </button>
                </div>
                {showPassword && (
                  <div>
                    <button onClick={() => navigate("/forgotpwd")} className="font-semibold text-main hover:text-main-hover">
                      Forgot password?
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Imagem */}
      <div className="w-full lg:w-2/3 hidden lg:block">
        <img src={image} alt="Sample" className="h-full w-full object-cover" />
      </div>
    </div>
  );
};

export default SignInPage;
