import React, { useContext, useState } from "react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { TiPin, TiPinOutline } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/Data";

const MenuItem = ({ item, togglePin, pinnedItems }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setV2RouteSelected } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(() => {
    const savedState = JSON.parse(localStorage.getItem("eno:menuState")) || {};
    return savedState[item.id] || false;
  });

  const toggleOpen = () => {
    setIsOpen((prevState) => {
      const newState = !prevState;

      const menuState = JSON.parse(localStorage.getItem("eno:menuState")) || {};
      menuState[item.id] = newState;

      localStorage.setItem("eno:menuState", JSON.stringify(menuState));

      return newState;
    });
  };

  const handleRedirect = (path) => {
    setV2RouteSelected({ ...path, permission: item.permission });
    navigate(path.navlink);
  };

  return (
    <div className="text-font-color">
      <button onClick={toggleOpen} className="flex justify-between items-center w-full p-3 transition-colors duration-200 border-l-0 border-r-0 border-b-0 border-t-[0.5px] border-solid">
        <span>{item.title}</span>
        {item.links.length > 0 && (isOpen ? <FaChevronDown /> : <FaChevronRight />)}
      </button>
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
        {item.links.length > 0 && (
          <div className="pl-8 bg-main">
            {item.links.map((subitem, index) => {
              const selected = location.pathname.includes(subitem.navlink);
              return (
                <div className={`${selected ? "bg-main-hover" : ""} flex mr-2 hover:bg-main-hover rounded-sm pr-2`} key={index}>
                  <button onClick={() => handleRedirect(subitem)} className="w-full text-left transition-colors duration-200 p-2.5">
                    {subitem.label}
                  </button>
                  <button onClick={() => togglePin(subitem)}>{pinnedItems.find((i) => i.label === subitem.label) ? <TiPin className="h-5 w-5" /> : <TiPinOutline className="h-4 w-4" />}</button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuItem;
