import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/Authorization";
import { DataContext } from "../context/Data";
import NoRouteV2 from "../components/StatusPages/NoRouteV2";

const Admin = React.lazy(() => import("./AdminRoutes"));

const AdmRoutes = () => {
  console.log("PortalRoutes Rendering...");
  const { AllowedTo, authData } = React.useContext(AuthContext);
  const { account } = React.useContext(DataContext);

  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    let default_route = authData?.user?.protected?.default_route;
    if (location.pathname === "/" && default_route) {
      navigate(default_route, { replace: true });
    }
  }, [authData?.user?.protected?.default_route, location.pathname, navigate]);

  // Static Compiled Routes
  let routes = [{ path: "admin/*", component: Admin, permission: "ROUTE_ADMIN_VIEW" }];

  return (
    <>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((item, index) => {
            let RouteComponent = item.component;

            const routeProps = (item.props && JSON.parse(item.props)) || {};

            return (
              <Route
                path={item.path}
                key={index}
                element={
                  <AllowedTo perform={item.permission} no={() => <NoRouteV2 />} data={account}>
                    <RouteComponent {...routeProps}></RouteComponent>
                  </AllowedTo>
                }
              ></Route>
            );
          })}
        </Routes>
      </React.Suspense>
    </>
  );
};

export default AdmRoutes;
