import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/Authorization";
import { DataContext } from "../context/Data";

// const Account = React.lazy(() => import("../components/Account"));
const Admin = React.lazy(() => import("../routes/AdminRoutes"));
const Overview = React.lazy(() => import("../views/Overview"));
const DTC = React.lazy(() => import("../views/DTC/DTC"));
const Depletion = React.lazy(() => import("../views/Depletion/Depletion"));
const SSO = React.lazy(() => import("../components/Auth/SSO"));
const NoRoute = React.lazy(() => import("../components/StatusPages/NoRoute"));
const NotFound = React.lazy(() => import("../components/StatusPages/NotFound"));

// Dynamic Routes
const dynamicComponents = {
  QlikIframe: React.lazy(() => import("../views/QlikIframe/QlikIframe")),
};

const PortalRoutes = () => {
  console.log("PortalRoutes Rendering...");
  const { AllowedTo, authData } = React.useContext(AuthContext);
  const { dynamicRoutes: dynRoutes, account } = React.useContext(DataContext);

  let location = useLocation();
  let navigate = useNavigate();

  // Direct default route / to firestore configured default_route.
  React.useEffect(() => {
    let default_route = authData?.user?.protected?.default_route || "dtc";
    if (location.pathname === "/" && default_route) {
      navigate(default_route, { replace: true });
    }
  }, [authData?.user?.protected?.default_route, location.pathname, navigate]);

  // Dynamic Routes from Data Context
  let dynamicRoutes = Object.keys(dynRoutes).map((path) => {
    return {
      path: path,
      ...dynRoutes[path], //Rest of the params
    };
  });

  // Static Compiled Routes
  let routes = [
    { path: "/", exact: true, component: Overview }, //^Does not exist currently
    { path: "/sso/jwt/login", exact: true, component: SSO },
    { path: "admin/*", component: Admin, permission: "ROUTE_ADMIN_VIEW" },
    { path: "dtc/*", qlik: true, component: DTC, permission: "ROUTE_DTC_VIEW" },
    { path: "depl/*", qlik: true, component: Depletion, permission: "ROUTE_DEPL_VIEW" },
    { path: "/*", exact: true, component: NotFound },
  ];

  //~ Add Dynamic Routes from Datastore
  routes = routes.concat(dynamicRoutes);

  return (
    <>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map((item, index) => {
            let RouteComponent = item.component;

            // Dynamic Routes will pull component from string value
            if (typeof item.component === "string") {
              RouteComponent = dynamicComponents[item.component];
            }
            const routeProps = (item.props && JSON.parse(item.props)) || {};

            return (
              <Route
                path={item.path}
                key={index}
                element={
                  <AllowedTo perform={item.permission} no={() => <NoRoute />} data={account}>
                    <RouteComponent {...routeProps}></RouteComponent>
                  </AllowedTo>
                }
              ></Route>
            );
          })}
        </Routes>
      </React.Suspense>
    </>
  );
};

export default PortalRoutes;
