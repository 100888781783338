const FirebaseConfig = {
  apiKey: "AIzaSyDoES_Abq9nh1VDx5iU1lrg5u4opX7Pqdg",
  authDomain: "enolytics.firebaseapp.com",
  databaseURL: "https://enolytics.firebaseio.com",
  projectId: "enolytics",
  storageBucket: "enolytics.appspot.com",
  messagingSenderId: "243285131779",
  appId: "1:243285131779:web:93753201c3f2fa33f90436",
  measurementId: "G-P5YW9GYZ7B",
};

const QlikConfig = {
  prefix: "myeno",
  host: "data.enolytics.com",
  port: 443,
  isSecure: true,
};

const QlikApps = {
  haasbros: "",
  haasbrosLegacy: "605da016-3bf2-4376-a9e2-f378282839de",
  depletion: "39022d88-689a-4cd1-ae22-65ab5452bdef",
  docNames: {
    dtc: "DTC",
    depletion: "Depletion",
    depl: "DEPL",
  },
};

const AstratoConfig = {
  baseURL: "https://app.astrato.io/",
  appName: {
    dtc: "DTC",
    "v2/dtc": "DTC",
    depl: "DEPL",
    "v2/depl": "DEPL",
    haasbros: "HAAS",
  },
};

export { FirebaseConfig, QlikConfig, QlikApps, AstratoConfig };
